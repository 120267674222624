@import 'node_modules/foundation-sites/scss/foundation';
@import 'variables';

$flex: true;

@if $flex {
  $global-flexbox: true !global;
}

@include foundation-global-styles;
@if not $flex {
  @include foundation-grid;
}
@else {
  @include foundation-flex-grid;
}
//@include foundation-typography;
@include foundation-forms;
//@include foundation-button;
//@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
//@include foundation-callout;
//@include foundation-card;
//@include foundation-close-button;
//@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
//@include foundation-responsive-embed;
//@include foundation-label;
//@include foundation-media-object;
@include foundation-off-canvas;
//@include foundation-orbit;
@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
@include foundation-reveal;
//@include foundation-switch;
//@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
//@include foundation-title-bar;
//@include foundation-tooltip;
//@include foundation-top-bar;
@include foundation-visibility-classes;
//@include foundation-float-classes;

@if $flex {
  @include foundation-flex-classes;
}


@import 'typography';
@import 'mixins';
@import 'general';
@import 'inputs';

// components
@import 'components/header';
@import 'components/footer';
@import 'components/product';
@import 'components/menu';
@import 'components/cart-summary';
@import 'components/errors';
@import 'components/search';
@import 'components/default';
@import 'components/defaultdate';
@import 'components/defaulttime';
@import 'components/lightgallery';

// pages
@import 'pages/home';
@import 'pages/catalog';
@import 'pages/product';
@import 'pages/cart';
@import 'pages/profile';
@import 'pages/cms';
@import 'pages/checkout';
@import 'pages/login';
@import 'pages/boutiques';
@import 'pages/contact';
@import 'pages/blog';

// slick
@import 'node_modules/slick-carousel/slick/slick.scss';
@import 'node_modules/slick-carousel/slick/slick-theme.scss';
@import 'node_modules/slick-lightbox/src/styles/slick-lightbox';

// intlTelInput
@import 'node_modules/intl-tel-input/build/css/intlTelInput';

.iti {
  width: 100%;
  margin-bottom: 16px;
}
.iti__flag {background-image: url("/skins/current-skin/images/flags/flags.png");}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {background-image: url("/skins/current-skin/images/flags/flags@2x.png");}
}

